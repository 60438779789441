import { Video } from "../somethings";

interface MyMusic extends Video {
  hasVideo: boolean;
}

export const myMusic: MyMusic[] = [
  {
    title: "halo (2024)",
    link: "https://youtu.be/4qW83fDQKa0?si=kXw8vh7NP6h86mPY",
    hasVideo: false,
  },
  {
    title: "i'm not sure (2024)",
    link: "https://www.youtube.com/watch?v=EoRnpzIL87s",
    hasVideo: true,
  },
  {
    title: "immaculate (2024)",
    link: "https://youtu.be/co1EJ-5Dz6w?si=in1xAelyqHqM9M3e",
    hasVideo: false,
  },
  {
    title: "my angel (2024)",
    link: "https://youtu.be/-XaV7HKVUhA?si=Od1CNuTagiv9MHGu",
    hasVideo: false,
  },
  {
    title: "never be sorry (2024)",
    link: "https://youtu.be/MmqfUoqFQZ0",
    notes: "",
    hasVideo: true,
  },
  {
    title: "delirious (2021)",
    link: "https://youtu.be/ocXRD2LHPnU",
    notes: "",
    hasVideo: true,
  },
  {
    title: "no shame (2021)",
    link: "https://youtu.be/_X_YIaR6mkw",
    notes: "",
    hasVideo: true,
  },
  {
    title: "valentine (2021)",
    link: "https://youtu.be/LlwpegGMfVA",
    notes: "",
    hasVideo: true,
  },
  {
    title: "bookshelves (2021)",
    link: "https://youtu.be/7SyakXQfFqw",
    notes: "",
    hasVideo: true,
  },
  {
    title: "let you know (2021)",
    link: "https://www.youtube.com/watch?app=desktop&v=ixiB5JlMyV4",
    hasVideo: false,
  },
  {
    title: "all you have to do (2020)",
    link: "https://youtu.be/DfLPkVFU_g8",
    hasVideo: true,
  },
  {
    title: "unkind (2020)",
    link: "https://youtu.be/Wyw3U4k0w6c",
    notes: "",
    hasVideo: true,
  },
  {
    title: "copenhagen (2020)",
    link: "https://youtu.be/z5c0W2hHfCo",
    notes: "",
    hasVideo: true,
  },
].map((el) => ({ ...el, contentType: "video" }));
